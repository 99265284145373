import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {BASE_URL} from '../../../config'
import axios from 'axios';
function ContentOfpurchase() {
  const [purchases, setPurchases] = useState([]);
  useEffect(() => {
    const getPurchases = BASE_URL+'/purchase-order';
    const token = localStorage.getItem('token');
    fetch(getPurchases, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setPurchases(data.data);
      })
      .catch(error => {
        console.error('Failed:', error);
      });
  }, []);
    const makePayment = async (purchasePrice, orderID) => {
      try {
        const response = await axios.post(BASE_URL+'/paypal-charge', {
          amount: purchasePrice,
          order_id: orderID,
        });
        window.location.href = response.data.redirect_url;
      } catch (error) {
        console.error('Error initiating payment:', error);
      }
    };
  return (
    <>
     <div className="tab-pane fade" id="v-pills-purchase" role="tabpanel" aria-labelledby="v-pills-purchase-tab">
        {/* table title*/}
        <div className="table-title-area">
          <h3>All Purchase</h3>
          <select id="order-category">
            <option value={"01"}>Show: Last 05 Order</option>
            <option value={"02"}>Show: Last 03 Order</option>
            <option value={"03"}>Show: Last 15 Order</option>
            <option value={"04"}>Show: Last 20 Order</option>
          </select>
        </div>
        {/* table */}
        <div className="table-wrapper">
          <table className="eg-table order-table table mb-0">
            <thead>
              <tr>
                <th>Title</th>
                <th>Bidding ID</th>
                <th>Bid Amount(USD)</th>
                <th>Image</th>
                <th>Status</th>
                <th>Payment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {purchases.map(purchase => (
              <tr>
                <td data-label="Title">{purchase.product.title}</td>
                <td data-label="Bidding ID">{purchase.id}</td>
                <td data-label="Bid Amount(USD)">{purchase.price}</td>
                <td data-label="Image"><img alt="images" src={purchase.product.image} className="img-fluid" /></td>
                <td data-label="Status" className="text-green">{purchase.status}</td>
                <td className="text-green">
                <button className='eg-btn btn--primary header-btn' onClick={() => makePayment(purchase.price, purchase.id)}>
                  Make Payment
                </button>
                </td>
                <td data-label="Action"><button className="eg-btn action-btn green"><img alt="perchesImage" src={process.env.PUBLIC_URL + "/images/icons/aiction-icon.svg"} /></button></td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        {/* pagination area */}
        <div className="table-pagination">
          <p>Showing 10 to 20 of 1 entries</p>
          <nav className="pagination-wrap">
            <ul className="pagination style-two d-flex justify-content-center gap-md-3 gap-2">
              <li className="page-item">
                <Link className="page-link" to={"#"} tabIndex={-1}>Prev</Link>
              </li>
              <li className="page-item"><Link className="page-link" to={"#"}>01</Link></li>
              <li className="page-item active" aria-current="page">
                <Link className="page-link" to={"#"}>02</Link>
              </li>
              <li className="page-item"><Link className="page-link" to={"#"}>03</Link></li>
              <li className="page-item">
                <Link className="page-link" to={"#"}>Next</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>   
    </>
  )
}

export default ContentOfpurchase