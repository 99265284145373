import React, { useState, useEffect } from 'react';
import AuctionDetailsInfo from './AuctionDetailsInfo'
import AuctionDetailsTab from './AuctionDetailsTab'
import {BASE_URL} from '../../../config'


function AuctionDetailsWrap(props) {
  const [auction, setAuction] = useState(null);
  const [refreshBidHistory, setRefreshBidHistory] = useState(() => {
    return () => {}
  });

  useEffect(() => {
    const getAuction = BASE_URL+'/auctions/'+props.id;
    fetch(getAuction)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setAuction(data.data);
      })
      .catch(error => {
        console.error('Failed:', error);
      });
  }, [props.id]);

  return (
    <>
      <div className="auction-details-section pt-120 pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg.png'} className="img-fluid section-bg-top" />
        <img alt="images" src={process.env.PUBLIC_URL + '/images/bg/section-bg.png'} className="img-fluid section-bg-bottom" />

        <div className="container">
          {auction !== null && (
            <>
              <AuctionDetailsInfo  auction={auction} onBid={() => refreshBidHistory()} onCurrentPriceChange={() => refreshBidHistory()} />
              <AuctionDetailsTab   auction={auction} setRefreshBidHistory={setRefreshBidHistory}/>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default AuctionDetailsWrap