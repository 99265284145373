import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import SignUpWrap from './SignUpWrap'

function SignUp() {
  return (
    <>
     <Breadcrumb pageName="Sign Up" pageTitle="Sign Up"/>   
     <SignUpWrap/>
    </>
  )
}

export default SignUp