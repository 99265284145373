import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LiveAuctionCard from '../LiveAuction.jsx/LiveAuctionCard'
import { BASE_URL } from '../../../config'
function LiveAuctionHome1() {
  const [auctions, setAuctions] = useState([]);
  useEffect(() => {
    const getAuctions = BASE_URL+'/auctions?limit=6&status=live';
    fetch(getAuctions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setAuctions(data.data);
      })
      .catch(error => {
        console.error('Failed:', error);
      });
  }, []);
  return (
    <>
      <div className="live-auction pb-120">
        <img alt="images" src={process.env.PUBLIC_URL + "/images/bg/section-bg.png"} className="img-fluid section-bg" />
        <div className="container position-relative">
          <img alt="images" src={process.env.PUBLIC_URL + "/images/bg/dotted1.png"} className="dotted1" />
          <img alt="images" src={process.env.PUBLIC_URL + "/images/bg/dotted1.png"} className="dotted2" />
          <div className="row d-flex justify-content-center">
            <div className="col-sm-12 col-md-10 col-lg-8 col-xl-6">
              <div className="section-title1">
                <h2>Live Auction</h2>
                <p className="mb-0">Explore on the world's best &amp; largest Bidding marketplace with our beautiful Bidding
                  products. We want to be a part of your smile, success and future growth.</p>
              </div>
            </div>
          </div>
          <div className="row gy-4 mb-60 d-flex justify-content-center">
            {auctions.map(auction => (
              <div key={auction.id} className="col-lg-4 col-md-6 col-sm-10">
                <LiveAuctionCard
                  image={auction.product.image_urls[0]}
                  price={auction?.product?.start_price}
                  title={auction?.product?.title}
                  startTime={auction?.start_time}
                  endTime={auction?.end_time}
                  status={auction?.dynamic_status}
                  id={auction?.id}
                />
              </div>
            ))}
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-4 text-center">
              <Link to={`${process.env.PUBLIC_URL}/live-auction`} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} className="eg-btn btn--primary btn--md mx-auto">View All</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LiveAuctionHome1