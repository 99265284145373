import React from "react";
import { Link } from "react-router-dom";
import {BASE_URL} from '../../../config'
import axios from 'axios';
function BuyBidCard(props) {
  const makePayment = async (price) => {
    try {
      const response = await axios.post(BASE_URL+'/buy-bids', {
        amount: price,
      });
      window.location.href = response.data.redirect_url;
    } catch (error) {
      console.error('Error initiating payment:', error);
    }
  };
  return (
    <>
      <div
        data-wow-duration="1.5s"
        data-wow-delay="0.2s"
        className="eg-card auction-card1 wow fadeInDown"
      >
        <div className="auction-img">
          <img alt="images" src={`${process.env.PUBLIC_URL} ${props.image}`} />
        </div>
        <div className="auction-content">
          <h4>
              {props.title}
          </h4>
          <p>
            Bids : <span>{props.qty}</span>
          </p>
          <p>Price : <span>${props.price}</span></p>
          <div className="auction-card-bttm">
            <button className='eg-btn btn--primary btn--sm' onClick={() => makePayment(props.price)}>
            Purchase Using Paypal
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuyBidCard;
