import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import { BASE_URL } from "../../config";
import moment from "moment";
function Footer(props) {

  const [posts, setPosts] = useState([]);

  const getData = () => {
    const endpoint = BASE_URL + '/articles?limit=3';
    fetch(endpoint)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setPosts(data.data);
      })
      .catch(error => {
        console.error('Failed:', error);
      });
  }

  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();

    getData();
  }, []);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <footer>
        <div className="footer-top">
          <div className="container">
            <div className="row gy-5">
              <div className="col-lg-3 col-md-6">
                <div className="footer-item">
                  <Link to={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}><img alt="images" className="footer-img" src={process.env.PUBLIC_URL + "/images/bg/footer-logo.png"} /></Link>
                  <p>Lorem ipsum dolor sit amet consecte tur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore.</p>
                  <form>
                    <div className="input-with-btn d-flex jusify-content-start align-items-strech">
                      <input type="text" placeholder="Enter your email" />
                      <button type="submit"><img alt="images"  src={process.env.PUBLIC_URL + "/images/icons/send-icon.svg"} /></button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
                <div className="footer-item">
                  <h5>Navigation</h5>
                  <ul className="footer-list">
                    <li><Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}live-auction`}>All Product</Link></li>
                    <li><Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}how-works`}>How It Works</Link></li>
                    <li><Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/login`}>My Account</Link></li>                   
                    <li><Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/blog`}>Our News Feed</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex justify-content-lg-center">
                <div className="footer-item">
                  <h5>Usefull Link</h5>
                  <ul className="footer-list">
                    {/* <li><Link to={`${process.env.PUBLIC_URL}/product`} onClick={scrollTop}>Help Center</Link></li> */}
                    <li><Link to={`${process.env.PUBLIC_URL}/faq`} onClick={scrollTop}>FAQs</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/login`} onClick={scrollTop}>Terms and Conditions</Link></li>
                    <li><Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/about`}>About Us</Link></li>
                    {/* <li><Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>Security Information</Link></li> */}
                    {/* <li><Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>Merchant Add Policy</Link></li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="footer-item">
                  <h5>Latest Feed</h5>
                  <ul className="recent-feed-list">
                    {posts?.map(post => (
                      <li key={post.id} className="single-feed">
                        <div className="feed-img">
                          <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}blog-details/${post.id}`}><img alt="images" src={post.image_url} /></Link>
                        </div>
                        <div className="feed-content">
                          <span>{moment(post.created_at).format('MMM DD, YYYY')}</span>
                          <h6><Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}blog-details/${post.id}`}>{post.title}</Link>
                          </h6>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row d-flex align-items-center g-4">
              <div className="col-lg-6 d-flex justify-content-lg-start justify-content-center">
                <p>Copyright 2023 <Link to={"#"}>NVT Trading</Link> | Design By ADMKSOL</p>
              </div>
              <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center align-items-center flex-sm-nowrap flex-wrap">
                <p className="d-sm-flex d-none">We Accepts:</p>
                <ul className="footer-logo-list">
                  <li><Link to={"#"} onClick={scrollTop}><img alt="images" src={process.env.PUBLIC_URL + "/images/bg/footer-pay1.png"} /></Link></li>
                  <li><Link to={"#"} onClick={scrollTop}><img alt="images" src={process.env.PUBLIC_URL + "/images/bg/footer-pay2.png"} /></Link></li>
                  <li><Link to={"#"} onClick={scrollTop}><img alt="images" src={process.env.PUBLIC_URL + "/images/bg/footer-pay3.png"} /></Link></li>
                  <li><Link to={"#"} onClick={scrollTop}><img alt="images" src={process.env.PUBLIC_URL + "/images/bg/footer-pay4.png"} /></Link></li>
                  <li><Link to={"#"} onClick={scrollTop}><img alt="images" src={process.env.PUBLIC_URL + "/images/bg/footer-pay5.png"} /></Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
