import React from 'react'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide, } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/autoplay";
import { useState } from 'react';
import { useEffect } from 'react';
import {BASE_URL} from '../../../config'
SwiperCore.use([ Pagination, Autoplay, EffectFade, Navigation]);
function Catagory() {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
      const getCategories = BASE_URL+'/categories';
      fetch(getCategories)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setCategories(data.data);
        })
        .catch(error => {
          console.error('Failed:', error);
        });
    }, []);
    const scrollTop = window.scrollTo({top:0,behavior:"smooth"})
    const caragorySlider = {
        slidesPerView: 1,
        speed: 1000,
        spaceBetween: 30,
        loop: true,
        autoplay: true,
        roundLengths: true,
        navigation: {
          nextEl: '.category-prev1',
          prevEl: '.category-next1',
        },
    
        breakpoints: {
            280:{
                slidesPerView: 1
              },
          440:{
            slidesPerView: 2
          },
          576:{
            slidesPerView: 2
          },
          768:{
            slidesPerView: 3,
            autoplay: true,
            speed: 1000,
          },
          992:{ 
            slidesPerView: 5
          },
          1200:{
            slidesPerView: 6
          },
          1400:{
            slidesPerView: 7
          },
         
        }
    }
  return (
    <>
    <div className="category-section pt-120 pb-120">
        <div className="container position-relative">   
          <div className="row d-flex justify-content-center">
            <Swiper {...caragorySlider} className="swiper category1-slider">
              <div className="swiper-wrapper">
              {categories.map(categories => (
                <SwiperSlide className="swiper-slide" key={categories.id}>
                  <div className="eg-card category-card1 wow animate fadeInDown" data-wow-duration="1500ms" data-wow-delay="200ms">
                    <div className="cat-icon">
                      <img src={categories.image_url} alt="" style={{height:"60px"}} />
                    </div>
                    <Link to={`${process.env.PUBLIC_URL}/live-auction/?category=${categories.id}`} onClick={scrollTop}>
                      <h5>{categories.title}</h5>
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
              </div>
            </Swiper>
          </div>
          <div className="slider-arrows text-center d-lg-flex d-none  justify-content-end">
            <div className="category-prev1 swiper-prev-arrow" tabIndex={0} role="button" aria-label="Previous slide"> <i className="bx bx-chevron-left" /> </div>
            <div className="category-next1 swiper-next-arrow" tabIndex={0} role="button" aria-label="Next slide"> <i className="bx bx-chevron-right" /></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Catagory